<template>
  <div>
    <Button size="large" type="text" @click="backHome">返回首页</Button>
    <Button size="large" type="text" @click="backPrev">返回上一页{{ second > 0 ? '(' + second + 's)' : '' }}</Button>
    <Button v-if="code == 401" size="large" type="text" @click="logout">退出登录</Button>
  </div>
</template>

<script>
import './error.less'
import { mapActions } from 'vuex'
export default {
  name: 'backBtnGroup',
  props: {
    code: String
  },
  data() {
    return {
      second: 0,
      timer: null
    }
  },
  methods: {
    ...mapActions([
      'handleLogout'
    ]),
    backHome() {
      this.$router.replace({
        name: this.$config.homeName
      })
    },
    backPrev() {
      this.$router.go(-1)
    },
    logout() {
      this.handleLogout().then(res => {
        this.$Message.success('退出成功')
        this.$router.push({name: 'login'})
      }).catch((err) => {
        this.$Message.error(err.message)
      })
    }
  },
  mounted() {
    if (this.second == 0) {
      return
    }
    this.timer = setInterval(() => {
      if (this.second === 0) this.backPrev()
      else this.second--
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
